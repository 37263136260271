import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tablemodal-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss']
})
export class TablemodalComponent implements OnInit {
  cols:any;
  tableData:any =[]
  constructor(public dialogRef: MatDialogRef<TablemodalComponent>,public dialog: MatDialog,public router: Router, @Inject(MAT_DIALOG_DATA) public data: any) {  this.cols = [
    {
      key: 's.no', display: 'S.No.', sort: false, config: { isIndex: true, isClickAble:true }

    },
    {
      key: 'orderStatus', display: 'Order Status', sort: true, config:{isClickAble:true }

    },
    {
      key: 'orderId', display: 'Order Id', sort: true, config:{isClickAble:true }

    },
    {
      key: 'orderDate', display: 'Order Date', sort: true,config: {isDate: true, format: 'dd-MM-yyyy',isClickAble:true}

    },
    {
      key: 'customerName', display: 'Customer Name', sort: true, config:{isClickAble:true }

    },
    {
      key: 'productName', display: 'Product Name', sort: true, config:{isClickAble:true }

    },
    // {
    //   key: 'mobile', display: 'Customer Number', sort: true, config:{isClickAble:true }

    // },
  ] }

  ngOnInit(){
    this.tableData = this.data.data;
  }
  onRowActioHandler(data){
    sessionStorage.setItem('packingData', JSON.stringify(data))
    this.dialogRef.close();
    this.router.navigate(['./main/order/order-management/packing/packing-master'])
  }
}