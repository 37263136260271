<!-- © 2021 .Kritin Digital solutions  All rights reserved.
 Original Author: Lokesh Rajput
 Original Date: 21 June 2021 -->

<div *ngIf="!data ">
  <app-no-data></app-no-data>
</div>
<div class="table_main_div {{class}}" *ngIf="data?.length > 0">
  <!--  sub header start-->
  <div class="row m-0 tableHead">
    <div class="col-sm-6">
      <div class="searchInputWrapper">
        <mat-icon matSuffix class="searchInputIcon">search</mat-icon>
        <input type="text" (keyup)="applyFilter($event.target.value)" placeholder="Search" class="searchInput">
      </div>
    </div>
    <div class="col-sm-6 text-end">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
        <mat-button-toggle [matMenuTriggerFor]="columnMenu" title="Column visibility" matTooltip="Rearrange Columns">
          <mat-icon fontSet="material-icons-outlined">view_column</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle (click)="changeSearchStatus()" title="Search" matTooltip="Column Wise Search">
          <mat-icon><span class="material-icons" fontSet="material-icons-outlined">filter_list</span></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle [excelExport]="data" [fileName]="excelName" aria-label="excel" title="Download Excel" matTooltip="Download Excel">
          <mat-icon fontSet="material-icons-outlined">description </mat-icon>
        </mat-button-toggle>
        <mat-button-toggle aria-label="excel" title="Download Pdf" (click)="pdf()" matTooltip="Download PDF">
          <mat-icon fontSet="material-icons-outlined">picture_as_pdf</mat-icon>
        </mat-button-toggle>
        <mat-menu #columnMenu="matMenu">
          <div cdkDropList class="mat-menu-list" (cdkDropListDropped)="columnMenuDropped($event)">
            <div class="item-box" *ngFor="let column of columnInfo; let i = index" cdkDrag
              [cdkDragData]="{columnIndex: i, columnTitle: column.id}">
              <mat-checkbox [checked]="!column.hidden" (click)="$event.stopPropagation();"
                (change)="toggleSelectedColumn(column.key)">
                {{ column.display}}
              </mat-checkbox>
            </div>
          </div>
        </mat-menu>
      </mat-button-toggle-group>
    </div>
  </div>
  <!--  sub header end-->
  <!-- grid table start -->
  <div class="mainTable">
    <table mat-table matSort [dataSource]="dataSource" [ngClass]="showSearch ? 'openSearch' : ''" class="Data_table">
      <ng-container *ngFor="let col of tableCols; let i = index" [matColumnDef]="col.key">
        <!-- thead start-->
        <th mat-header-cell *matHeaderCellDef class="{{col.key}}">
          <span *ngIf="col.key !== 'select'" [disabled]="!col.sort" [mat-sort-header] class="shorting">
            {{ tableCols[i]["display"] }}
          </span>
          <ng-container *ngIf="col.sort&&!col.hidden && showSearch">
            <div class="searchContainer">
              <mat-icon>search</mat-icon>
              <input matInput class="form-field filtersearch" type="text"
                (keyup)="filterData(col.key,$event.target.value)">
            </div>
          </ng-container>
          <ng-container *ngIf="col.key === 'select'">
        <th mat-header-row>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
      </ng-container>
      </th>

      <!-- thead end-->
      <!-- tbody start selection.isSelected(element) element[col.key]--> 
      <td mat-cell *matCellDef="let element ;let j =index" class="{{col.key}}">
        <ng-container *ngIf="col.key === 'select'">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null"
            [checked]="element[col.key] || selection.isSelected(element) || false " [aria-label]="checkboxLabel(element)" (change)="selectRow(element)">
          </mat-checkbox>
        </ng-container>
        <!--  Checking if it's a normal column or column with specific config -->
        <ng-container *ngIf="!col.config; else hasConfig">
          <span class="text-capitalize">
            {{ element[col.key] }}
          </span>

        </ng-container>
        <!-- If this column has a config object so we will display depending on our sitted config -->
        <ng-template #hasConfig>
          <ng-container>
            <span class="text-capitalize pointer" *ngIf="col.config.isClickAble " (click)="rowClickEvent(element)">
              <span *ngIf="!col.config.isDate">
                {{ element[col.key] }}

              </span>
              <ng-container *ngIf="col.config.isDate">
                {{ element[col.key] | date:col.config.format }}
              </ng-container>

            </span>
          </ng-container>
          <ng-container *ngIf="col.config.isInputType">
            <input type="text" class="form-control input-sm" [(ngModel)]="element[col.key]"
              oninput="this.value = this.value.replace(/[^0-9]/g, '');" min=0 required />

          </ng-container>

          <!-- if this attribute is a boolean, we will show values from config object depending on his state -->
          <!-- Index -->
          <ng-container *ngIf="col.config.isIndex">
            {{j+1}}
          </ng-container>
          <!-- if this attribute is a date, we will format-+
               it depending on format value in config object -->
          <ng-container *ngIf="col.config.isDate && !col.config.isClickAble">
            {{ element[col.key]| date:col.config.format}}
          </ng-container>

          <!--  to access inner data -->
          <ng-container *ngIf="col.config.isInner">
            {{ element[col.key][col.config.innerKey]}}
          </ng-container>

         

          <!-- to show status -->
          <ng-container *ngIf="col.config.isStatus">
            <span [ngClass]="element[col.key] === 'Active' ? 'active' : 'inActive'"> {{ element[col.key] }} </span>
          </ng-container>
          <ng-container *ngIf="col.config.iscolor">
            <span class="colorDot" [style.background-color]="element[col.key]"> </span>
          </ng-container>
          <!-- <ng-container *ngIf="col.config.isTextEnd">
            <span  class="text-end" >{{ element[col.key]}}</span>
          </ng-container> -->

          <!-- draft -->
          <ng-container *ngIf="col.config.checkDraft">
            {{ element[col.key] }}
            <!-- {{element[col.config.parameterName]}} -->
            <span *ngIf="element[col.config.parameterName]" class="draft"> (draft) </span>
          </ng-container>
          <!--  to  show image -->
          <ng-container *ngIf="col.config.isImage">
            <img *ngIf="element[col.key] !==null" [src]="element[col.key]" class="imageClass"
              alt="./assets/images/paf-logo.png" />
            <img *ngIf="element[col.key] === null" src="./assets/images/no-image.png" class="imageClass"
              alt="./assets/images/paf-logo.png" />
          </ng-container>

          <!--  to show pdf -->
          <ng-container *ngIf="col.config.isPdf">
            <img src="./assets/images/pdf-logo.png" class="imageClass" alt="'image'" />
          </ng-container>

          <!-- if this column is action, we will loop through each action and show it as a mat-button -->
          <ng-container *ngIf="col.config.isAction">
            <!-- <button *ngFor="let action of col.config.actions" (click)="emitter.emit(action)" mat-button>{{action}}</button> -->
            <button mat-icon-button *ngFor="let action of col.config.actions" matTooltip="{{action}}" class="{{action}}"
              (click)="actionHandler(action,element)" class="actionBtn">
              <mat-icon fontSet="material-icons-outlined"><span class="{{action}}">{{action}}</span></mat-icon>
            </button>
          </ng-container>
          <ng-container *ngIf="col.config.isButton">
            <!-- <button *ngFor="let action of col.config.actions" (click)="emitter.emit(action)" mat-button>{{action}}</button> -->
            <button mat-icon-button *ngFor="let action of col.config.actions" class="{{action}}"
              (click)="actionHandler(action,element)" class="actionBtn">
              <ng-container *ngIf="action === 'Active'">
                <span class="btn btn-outline-primary {{action}}" *ngIf="element.statusEnumId == 2">Active</span>
                <span class="btn btn-outline-primary {{action}}" *ngIf="element.statusEnumId == 1">Deactive</span>
             <!-- <span class="btn btn-outline-primary {{action}}">{{action}}</span> -->
              </ng-container>
            </button>
          </ng-container>


        </ng-template>
      </td>
      <!-- tbody end-->
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="keys; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: keys"></tr>
    </table>
    <!-- <div *ngIf="!data.length" class="text-center no_data">
      <h5 class="mb-0">No Record Found</h5>
     
    </div> -->

  </div>
  <!-- grid table end -->
</div>
<!-- [length]="data.length"
[pageSize]="pageSize" -->
<!-- pagination start -->
<!-- [pageSizeOptions]="[7,10, 25, 100]"
 [pageSize]="50"
 [pageSizeOptions]="[20,50, 75, 99]"
 [hidden]="data?.length"
  showFirstLastButtons -->
 
    <mat-paginator  [hidden]="!data"  (page)="handlePageEvent($event)" 
    [pageSize]="50"
    showFirstLastButtons
    [pageSizeOptions]="[100,200, 500, 999]"
    
      class="mat-paginator-sticky pagination_set">
    </mat-paginator>
    <!-- <ng-container *ngIf="data.length >0">  </ng-container> -->

<!-- pagination end -->



