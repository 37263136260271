import { Component, Input, OnInit } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Subscription } from 'rxjs';
import { IGetInvoiceDetailData } from 'src/app/core/interfaces/order/order-management/get-invoiceDetail-data';
import { DeleteInvoiceLineItemsModel } from 'src/app/core/models/order/order-management/delete-invoice-lineItems-model';
import { InvoiceModel } from 'src/app/core/models/order/order-management/invoice-master-model';

import { InvoiceTable,InvoiceHSNTableData } from 'src/app/core/constants/common-constant';
import { CommonService } from 'src/app/core/services/common.services';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OrderManagementService } from 'src/app/core/services/order/order-management/order-management-services';
import { companyService } from 'src/app/core/services/company/company-services';

@Component({
  selector: 'app-pdf-invoice',
  templateUrl: './pdf-invoice.component.html',
  styleUrls: ['./pdf-invoice.component.scss']
})
export class PdfInvoiceComponent implements OnInit {
  @Input() tableData:any=[]
  @Input() data:any=[]
  @Input() InvoiceId:number=0

  // invoiceForm: FormGroup
  invoiceDataCompletd: any = {}
  userData: any = {}
  subscription: Subscription[] = [];
  invoiceId: number;
  invoiceProductDetail: IGetInvoiceDetailData[] = [];
  disabledDispatchBtn: boolean = false;
  pdfContent: any;
  shopData: any = {}
  invoiceDetail: any = [];
  shippingAdressData = [];
  invoiceTableData: any;
  beforTaxAmount: number;
  beforeTax: number;
  taxAmount: number;
  afterTax: number;
  tableArray: any = [];
  totalBeforTaxAmount: number = 0;
  totalAfterTaxAmount: number = 0;
  totalTaxAmount: number = 0;
  popupMessage: string;
  dispatchModel = new InvoiceModel()
  dispatchArray: any[];
  deleteInvoiceLineItemsModel = new DeleteInvoiceLineItemsModel()
  items: any;
  totalQty: number = 0;
  totalMrp: number = 0;
  taxableValue: number = 0;
  amountIntoWords: string;
  totalFreeQty: number = 0;
  totalBeforeRate: number = 0;
  totalAfterRate: number = 0;
  total: number = 0;
  totalTaxAble: number = 0;
  IGST: any;
  CGST: any;
  SGST: any;
  invoiceHsnTable: any = [];
  //orderDate: string;
  primaryColor:string='#01139b'
  secColor:string='#e2e6ff'
  tbColor:string='#8d93ba'

  gross_amount :number;
  total_taxable :number;
  total_discount:number=0;
  total_taxamount :number=0;
  net_amount :number=0;
  roundoff :any=0;
  GST: any;
  
  constructor(public router: Router,  private toastr: ToastrService, private companyService:companyService,
   private commonService: CommonService, private orderManagementService: OrderManagementService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('user'))
    this.invoiceDataCompletd = JSON.parse(sessionStorage.getItem('invoiceDataCompletd'))
    this.shopData = JSON.parse(sessionStorage.getItem('shopData'))
    this.getInvoicedetail();
    this.getCoampnyDetails()
  }

  companyDetails:any=[]
  company:any={}
  getCoampnyDetails() {
    this.subscription.push(this.companyService.getComapnyDetails(0,1).subscribe((res) => {
      if (res.statusCode === 200) {
        this.companyDetails = res.data;
        this.company =this.companyDetails[0]
        console.log('companyDetails',this.companyDetails)
        // this.companyDetails =[]
      }
      
      else {
       
        this.toastr.warning(res.message)
      }
    
    }))
  }
  
  getInvoicedetail() {
    this.subscription.push(this.orderManagementService.getInvoiceDetailData(this.invoiceDataCompletd.id, 0, 0, this.shopData.id).subscribe((res) => {
      if (res.statusCode === 200) {
        this.invoiceDetail = res.data[0];
        this.gross_amount =  this.invoiceDetail.gross_amount 
        this.total_taxable = this.invoiceDetail.total_taxable
        this.total_taxamount =  this.invoiceDetail.total_taxamount
        this.total_discount = this.invoiceDetail.total_discount
        this.roundoff = Number(Math.round(this.invoiceDetail.net_amount) - (this.invoiceDetail.net_amount)).toFixed(2)
        this.net_amount = Math.round(this.invoiceDetail.net_amount)
        // console.log(this.invoiceDetail,"0000000000-----",  this.roundoff)
        this.shippingAdressData = this.invoiceDetail.shippingOrderAddress
        this.invoiceTableData = this.invoiceDetail.getInvoiceDetailfunctionArray
        // this.setForValue();
        // console.log(this.invoiceDetail.stateId !== this.invoiceDetail?.shippingOrderAddress?.stateId)
        // if(this.invoiceDetail.stateId != this.invoiceDetail?.shippingOrderAddress?.stateId){
        //    this.isIGST= false
        // }
        // else{
        //   this.isIGST= true
        // }
        
        this.invoiceTable();
        // console.log(this.isIGST,'invoice shopW.ise list', this.invoiceDetail)

      } else {
        this.toastr.warning(res.message)
      }
    }))
  }

  invoiceTable(){
    this.subscription.push(this.orderManagementService.getInvoiceTableData(this.invoiceDataCompletd.id, 0, 0, this.shopData.id).subscribe((res) => {
      if (res.statusCode === 200) {
        this.tableArray = res.data;
        this.invoiceHsnTable = this.tableArray[this.tableArray.length-1]
        console.log("0000000000", this.invoiceHsnTable[0].IGST )
        this.GST = this.invoiceHsnTable[0].IGST
        if(this.invoiceHsnTable[0].IGST == 0){
           this.isIGST= false
        }
        else{
          this.isIGST= true
        }
        this.tableArray.pop()
        this.tableArray.forEach((element,index) => {
         this.tableArray[index].beforTaxRate = element.beforTaxRate-(element.beforTaxRate*element.saleDiscount/100)
         this.tableArray[index].afterTaxRate=  element.afterTaxRate-(element.afterTaxRate*element.saleDiscount/100)
        });
        this.calculateTotalAmount()
        console.log(' tableArray list', this.tableArray)
      } else {
        this.toastr.warning(res.message)
      }
    }))
  }

 
  

  //function for validate  dispatch Qty.
  validateNumber(event) {
    const tableData = this.tableArray
    let count = 0;

    for (let i = 0; i < tableData.length; i++) {
      if (tableData[i].dispatchQuantity === undefined || tableData[i].noOfProduct < tableData[i].dispatchQuantity ||
        tableData[i].dispatchQuantity === '' || tableData[i].dispatchQuantity <= 0) {
        count++
        this.disabledDispatchBtn = true;
      }
    }
    console.log('tableData', tableData)
    if (count === 0) {
      this.disabledDispatchBtn = false;
    }

    this.calculateTotalAmount();

  }





  dispatchInvoiceItems() {
    this.dispatchModel.invoiceId = this.invoiceDetail.id
    this.dispatchModel.customerId = this.invoiceDetail.customerId
    this.dispatchModel.discountAmount = this.invoiceDetail.discountAmount
    this.dispatchModel.orderId = this.invoiceDataCompletd.orderId
    this.dispatchModel.otherCharges = this.invoiceDataCompletd.otherCharges
    this.dispatchModel.paymentMode = this.invoiceDetail.paymentMode
    this.dispatchModel.paymentType = this.invoiceDataCompletd.paymentType
    this.dispatchModel.shopId = this.invoiceDetail.shopId
    this.dispatchModel.statusEnumId = this.invoiceDetail.statusEnumId
    this.dispatchModel.subtotal = this.invoiceDetail.subtotal
    this.dispatchModel.totalTax = this.invoiceDetail.totalTax
    this.dispatchModel.actionbyLoginUserId = this.userData.adminId
    this.dispatchModel.actionbyUserTypeEnumId = this.userData.userEnumId
    this.dispatchArray = []
    this.tableArray.forEach(element => {
      const object = {
        invoice_detail_id: element.invoiceDetailId,
        product_id: element.productId,
        dispatch_quantity: element.dispatchQuantity,
        per_product_price: 0,
        tax_amount: 0,
        free_no_of_item: element.free_no_of_item,
        sale_discount: element.sale_discount

      }
      this.dispatchArray.push(object)
    });

    this.dispatchModel.invoiceDetail = this.dispatchArray
    console.log('invoiceDetailArray', this.dispatchModel)
    this.subscription.push(this.orderManagementService.addcompletInvoiceDetail(this.dispatchModel).subscribe((res) => {
      if (res.statusCode === 200) {
        this.toastr.success(res.message)
        // this.backButton();
      } else {
        this.toastr.warning(res.message)
      }
    }))
  }


  deleteInvoiceLineItem(items) {
    console.log('items', items)
    this.deleteInvoiceLineItemsModel.orderId = items.orderId,
      this.deleteInvoiceLineItemsModel.invoiceDetailId = items.invoiceDetailId,
      this.deleteInvoiceLineItemsModel.invoiceId = items.invoiceId,
      this.deleteInvoiceLineItemsModel.productId = items.productId,
      this.deleteInvoiceLineItemsModel.remark = items.remarks
    this.deleteInvoiceLineItemsModel.actionbyLoginUserId = Number(this.userData.adminId)
    this.deleteInvoiceLineItemsModel.actionbyUserTypeEnumId = Number(this.userData.userEnumId)
    this.subscription.push(this.orderManagementService.deleteInvoiceLineItems(this.deleteInvoiceLineItemsModel).subscribe((res) => {
      if (res.statusCode === 200) {
        this.toastr.success(res.message)
      } else {
        this.toastr.warning(res.message)
      }
    }))
  }


  onClickButton($event){
    console.log(this.tableData,this.data,"ksdakfjsdkjjj")
    this.downloadInvoicePdf(61)
  }

  downloadInvoicePdf(event){
    console.log('event',event)
    if(event==107){
      this.generatePosPdf()
    }
    else{
      this.generatePdf()
    }
 }


  
  // calculateTotalAmount() {
  //   this.tableArray.forEach(element => {
  //     console.log('ele', element)
  //     const dispatchQty = Number(element.dispatchQuantity)
  //     this.total = this.total + dispatchQty*Number(element.afterTaxRate)
  //     this.totalTaxAble = this.totalTaxAble + dispatchQty*Number(element.taxable)
  //     this.totalBeforeRate = this.totalBeforeRate + Number(element.beforTaxRate)
  //     this.totalAfterRate = this.totalAfterRate + Number(element.afterTaxRate)
  //     this.totalQty = Number(this.totalQty) + Number(element.dispatchQuantity)
  //     this.totalFreeQty = Number(this.totalFreeQty) + Number(element.freeNoOfItem)
  //     this.totalBeforTaxAmount = Number(this.totalBeforTaxAmount) + (element.beforTaxRate * element.dispatchQuantity)
  //     this.totalTaxAmount = (Number(this.totalTaxAmount) + (element.beforTaxRate*element.taxPercent/100) * element.dispatchQuantity) 
  //     this.totalAfterTaxAmount = Number(this.totalAfterTaxAmount) +  (element.afterTaxRate * element.dispatchQuantity)
  //     this.amountIntoWords = this.convertNumberToWords(Math.round(this.total))
      
  //     this.IGST = element.gstItem.IGST
  //     this.CGST = element.gstItem.CGST
  //     this.SGST =  element.gstItem.SGST
    
  //   });
  // }
netAmount:Number =0
totalTaxableAmount =0
totalTrader_SchemeDiscount =0
isIGST:boolean =false
  calculateTotalAmount() {
    this.tableArray.forEach(element => {
      const dispatchQty = Number(element.dispatchQuantity)
      this.total = this.total + dispatchQty*Number(element.orderAfter_tax_product_amount)
     this.total = Number(Math.round(this.total).toFixed(2))
      this.totalTaxAble = this.totalTaxAble + dispatchQty*Number(element?.orde_sales_price)
      this.totalBeforeRate = this.totalBeforeRate + Number(element?.orderUnitPrice)// beforTaxRate)
      this.totalAfterRate = this.totalAfterRate + Number(element?.orderAfter_tax_product_amount)
      this.totalQty = Number(this.totalQty) + Number(element.dispatchQuantity)
      this.totalFreeQty = Number(this.totalFreeQty) + Number(element.freeNoOfItem)
      this.totalTaxableAmount =this.totalTaxableAmount +(Number(element?.orde_sales_price) *Number(element?.dispatchQuantity))
      this.totalBeforTaxAmount = this.totalBeforTaxAmount +(Number(element?.orde_sales_price) *Number(element?.dispatchQuantity))
      //Number(this.totalBeforTaxAmount) + (element?.orderUnitPrice * element.dispatchQuantity)-(element.beforTaxRate*element.saleDiscount/100)
      this.totalTaxAmount = (Number(this.totalTaxAmount) + ((Number(element?.orde_sales_price) *Number(element.dispatchQuantity))*element.orderTax_per/100))
      this.totalAfterTaxAmount =  this.totalAfterTaxAmount +(Number(element?.orderAfter_tax_product_amount) *Number(element.dispatchQuantity))
      //Number(this.totalAfterTaxAmount) +  (element.orderAfter_tax_product_amount * element.dispatchQuantity)-(element.afterTaxRate*element.saleDiscount/100)
      // this.amountIntoWords = this.convertNumberToWords(Math.round(this.totalAfterTaxAmount).toFixed(2))
      this.IGST =  element.gstItem.IGST
      this.CGST =  element.gstItem.CGST
      this.SGST =  element.gstItem.SGST

      this.totalTrader_SchemeDiscount = this.totalTrader_SchemeDiscount+( Number(element.orderTotal_discount ) *Number(element.dispatchQuantity))
      
    });
    console.log(this.totalBeforTaxAmount, this.IGST,this.SGST, this.CGST, "00000000000000")



  //  this.roundOff =  Math.round(Number(this.invoice.net_amount)) -(Number(this.invoice.net_amount))
    // this.netAmount =Math.round(Number(this.invoice.net_amount))
    this.amountIntoWords = this.convertNumberToWords(this.total)
  //   console.log(this.roundOff, this.netAmount)

  }


  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j] as any);
            n_array[i] = 0;
          }
        }
      }
      let value;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string + 'Rupees';
  }

  generatePdf() {
    // this.generatePDFNew()
    const documentDefinition = {
      pageSize: 'A4',
      // pageSize: {
      //   width: 595.28,            // width: 595.28,
      //   height: 'auto'
      // },

      // by default we use portrait, you can change it to landscape if you wish
      pageOrientation: 'landscape',

      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [25, 25, 25, 25],


      "content": [
        {
          "nodeName": "DIV",
          "id": "demo",
          "stack": [

            //new header
            {
              "nodeName": "TABLE",
              "fontSize": 11,
              "fillColor":"e2e6ff",
              "color":"#000",
              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "margin": [
                        10,
                        5,
                        5,
                        10
                      ],
                      "stack": [
                        {
                          "text":this.company?.companyName || "Jai Bharat Elec. Co.",
                          "nodeName": "H1",
                          "fontSize": 18,
                          "bold": true,
                          // "color":"#01139b",
                          // "marginLeft": 0,
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-h1",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "text-start"
                          ]
                        },
                        {
                          "text": this.company?.companyAddress,//||"2-Jail Road Indore-452001",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Phone No. : "+ this.company?.contactNumber,//0731-2534606,",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": " E-Mail : "+ this.company?.emailId,//jaibharatindore@gmail.com",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "GST No.:- "+ this.company?.gstNumber,
                          //23BBPPK3343D1ZX",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "text-start",
                            "mt-3"
                          ]
                        }
                      ],
                      "width": "50%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "marginBottom": 5,
                      "marginLeft": 5,
                      "margin": [
                        10,
                        10,
                        10,
                        10
                      ],
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          
                          "border": [
                            false,
                            false,
                            false,
                            false
                          ],
                         
                          "stack": [
                            {
                              "text": "Sales Tax Invoice",
                              "nodeName": "H1",
                              "alignment": "right",
                              "fontSize": 16,
                              "bold": true,
                              "marginBottom": 2,
                              "style": [
                                "html-h1",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "text-end"
                              ]
                            },
                            {
                              "text": "Invoice No." + this.invoiceDetail.invoiceOrderNumber,
                              "nodeName": "P",
                              "alignment": "right",
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": "Invoice Date : " + this.datePipe.transform(this.invoiceDetail.createdDate, 'dd/MM/yyyy'),
                              "nodeName": "P",
                              "alignment": "right",
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "50%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "50%",
                  "50%"
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 20,
              "style": [
                "html-table"
              ]
            },

            {
              "nodeName": "TABLE",
              "fontSize": 11,
              "width": "auto",
              // "fillColor":this.seccolor,
              "border": [
                false,
                false,
                false,
                false
              ],

              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "text": "Name & Billing Address of Consignee :",
                          "nodeName": "P",
                          "margin": [
                            0,
                            2,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": this.invoiceDetail.customerName,
                          "nodeName": "H2",
                          "fontSize": 16,
                          "bold": true,
                          "marginBottom": 2,
                          "style": [
                            "html-h2",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ],
                        },
                        {
                          "text": this.invoiceDetail.address,
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Phone : " + this.invoiceDetail.mobile,
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "State Name : " + this.invoiceDetail.stateName,
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },

                        {
                          "text": "GSTIN : " + this.invoiceDetail.gstNumber,
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "DL No. : ",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        }
                      ],
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],

                      "stack": [
                        {
                          "text": "Name & Shipping Address of Consignee :",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text":this.shippingAdressData[0]?.contactPersonName ||'NA',
                          "nodeName": "H2",
                          "fontSize": 16,
                          "bold": true,
                          "marginBottom": 2,
                          "style": [
                            "html-h2",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": this.shippingAdressData[0]?.address ||'NA',
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Phone :" + this.shippingAdressData[0]?.mobileNumber || 'NA',
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "State Name : " + this.shippingAdressData[0]?.stateName  || 'NA',
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },

                        // {
                        //   "text": "GSTIN : 23BBPPK3343D1ZX",
                        //   "nodeName": "P",
                        //   "margin": [
                        //     0,
                        //     1,
                        //     0,
                        //     1
                        //   ],
                        //   "style": [
                        //     "html-p",
                        //     "html-td",
                        //     "html-tr",
                        //     "html-tbody",
                        //     "html-table"
                        //   ]
                        // },
                        {
                          "text": "DL No. : ",
                          "nodeName": "P",
                          "margin": [
                            0,
                            1,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        }
                      ],
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "text": [
                            {
                              "text": "Customer Type :",
                              "margin": [
                                0,
                                1,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": this.invoiceDetail.userType,
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],
                          "nodeName": "P",
                          "margin": [
                            0,
                            2,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        // {
                        //   "text": "Invoice No. DG/21-22/1531  ",
                        //   "nodeName": "H4",
                        //   "fontSize": 12,
                        //   "bold": true,
                        //   "marginBottom": 5,
                        //   "style": [
                        //     "html-h4",
                        //     "html-td",
                        //     "html-tr",
                        //     "html-tbody",
                        //     "html-table"
                        //   ]
                        // },

                        {
                          "text": "\n",
                          "nodeName": "BR"
                        },
                        {
                          "text": "Order No. : # " + this.invoiceDetail.orderId,
                          "nodeName": "H4",
                          "fontSize": 10,
                          "bold": true,
                          "marginBottom": 2,
                          "style": [
                            "html-h4",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Order By :  " + this.invoiceDetail.orderNameStatus,
                          "nodeName": "H4",
                          "fontSize": 10,
                          "bold": false,
                          "marginBottom": 2,
                          "style": [
                            "html-h4",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": [
                            {
                              "text": "Order Date :",
                              "margin": [
                                0,
                                2,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },

                            {
                              "text": this.datePipe.transform(this.invoiceDetail.orderDate, 'dd/MM/yyyy'),
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],

                          "nodeName": "P",
                          "margin": [
                            0,
                            2,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": [
                            {
                              "text": "Order Type :",
                              "margin": [
                                0,
                                2,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },

                            {
                              "text":this.invoiceDetail.orderTypeStatus,
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],

                          "nodeName": "P",
                          "margin": [
                            0,
                            2,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        }
                      ],
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "33.5%",
                  "33.5%",
                  "33%"
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 15,
              "style": [
                "html-table"
              ]
            },
            this.getPdfTableData(this.tableArray),
            this.getPdfHSNTableData(this.invoiceHsnTable),
            {
              "nodeName": "DIV",
              "fontSize": 10,
              "stack": [
                {
                  "nodeName": "TABLE",
                  "width": "100%",
                  "border": [
                    false,
                    false,
                    false,
                    false
                  ],
                  "table": {
                    "width": "100%",
                    "border": [
                      false,
                      false,
                      false,
                      false
                    ],
                    "body": [
                      [
                        {
                          "text": "Freight",
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                          "nodeName": "TD",
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped",
                            "text-end"
                          ],
                          "colSpan": 3
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": "0.00",
                          "nodeName": "TD",
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                          "colSpan": 3,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": "",
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                        },
                        // {
                        //   "text": "GROSS AMOUNT",
                        //   "color":this.primaryColor,
                        //   "border":[true,true,true,true],
                        //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                        //   "fillColor":  this.secColor,
                        //   "nodeName": "TD",
                        //   "alignment": "right",
                        //   "fontSize": 12,
                        //   "bold": true,
                        //   "style": [
                        //     "html-td",
                        //     "html-tr",
                        //     "html-tbody",
                        //     "html-table",
                        //     "html-div",
                        //     "invoice",
                        //     "mb-5",
                        //     "invoiceDes2",
                        //     "table-responsive",
                        //     "table",
                        //     "table-striped",
                        //     "text-end"
                        //   ],
                        //   "colSpan": 2
                        // },
                        {
                          "text": "",
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                        },
                        {
                          "text": "",
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                        },
                        {
                          "text": "GROSS AMOUNT : " + (this.gross_amount).toFixed(2),
                          "border":[true,true,true,true],
                          // "fontSize":12,
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                          "nodeName": "TD",
                          // "colSpan": 2,
                          "alignment": "right",
                          // "background":"yellow",
                          //  "background":"#c1c9ff",
                          "color":"#01139b",
                          "fontSize": 10,
                          "bold": true,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped",
                            "text-end"
                          ]
                        }
                      ],
                      [
                        {
                          "nodeName": "TD",
                          "border":[true,false,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor": this.secColor,
                          
                           "width": "100%",
                          //  "background":"#c1c9ff",

                          "stack": [
                            {
                              "text": "Amt.of Tax Reverse Charges",
                              "nodeName": "P",
                              "border":[true,false,false,true],
                              "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                            // "fillColor":  this.secColor,
                              "margin": [
                                0,
                                1,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "text": "Rupees : " +  this.amountIntoWords + " Only/-",                      
                               "nodeName": "P",
                               "fontSize":12,
                              //  "background":"#c1c9ff",
                              "color":"#01139b",
                               "bold": true,
                              "margin": [
                                0,
                                1,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "text": "Note :",
                              "nodeName": "P",
                              "margin": [
                                0,
                                2,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "nodeName": "DIV",
                              "stack": [
                                {
                                  "text": "Jai Bharat Electric Co.",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    1,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },
                                {
                                  "text": "Axis Bank ", 
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    0,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },

                                {
                                  "text": "Branch: Sapna Sangita Road,Indore",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    0,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },
                                {
                                  "text": "Current Account: 503010200003414, IFSC Code: UTIB0000503",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    0,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                }
                              ]
                            }
                          ],
                          "colSpan": 8,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": "",
                          "border":[true,true,true,true],
                              "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                        },
                        {
                          "text": "",
                          "border":[true,true,true,true],
                              "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                        },
                        {
                          "text": "",
                          // "border":[true,true,true,true],
                          //     "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                        },
                        {
                          "text": "",
                          // "border":[true,true,true,true],
                          //     "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                        },
                        {
                          "nodeName": "TD",
                          "fontSize":10,
                          "border":[true,true,true,true],
                          "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                          "fillColor":  this.secColor,
                          // "bold": true,
                          "alignment": "right",

                          "stack": [
                            {
                              "nodeName": "TABLE",
                              "marginBottom": 0,
                              "marginTop": 0,
                              "marginLeft": 0,
                              "marginRight": 0,
                              // "border":[true,true,true,true],
                              // "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                              "fillColor":  this.secColor,
                              "style": [
                                "html-table",
                                "html-div"
                              ],
                              "table": {
                              //   "border":[true,true,true,true],
                              // "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                                
                                "body": [
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "DISCOUNT",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.total_discount).toFixed(2) || 0 ,
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "TOTAL TAXABLE",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.total_taxable ).toFixed(2)|| 0,
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    
                                    {
                                      
                                      "text": [
                                        
                                        
                                        {
                                          "text": "CGST",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          // "border":[true,true,true,true],
                                          // "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                                          "width": "100%",
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.isIGST?0:Number(this.totalTaxAmount/2)).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": "100%",
                                          // "color":"red",
                                          // "background":"yellow",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border":[false,false,false,false],
                                      "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                                      "alignment": "right",
                                      // "border": [
                                      //   false,
                                      //   false,
                                      //   false,
                                      //   false
                                      // ],
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        {
                                          "text": "SGST",
                                          "nodeName": "SPAN",
                                          // "fontSize":12,
                                          "alignment": "right",
                                          "float":"left",
                                          
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.isIGST?0:Number(this.totalTaxAmount/2)).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "alignment": "right",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "IGST",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.isIGST?Number(this.totalTaxAmount):0).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "CR/DR Note",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": "0",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "R/OFF AMT.",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.roundoff),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,    
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        
                                        {
                                          "text": "Net Amount(Rs.)",
                                          "nodeName": "SPAN",
                                          "fontSize":12,
                                          "bold":true,
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 100,
                                          "color": this.primaryColor,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "fontSize":12,
                                          "color": this.primaryColor,

                                          "bold":true,
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": Math.round(this.net_amount).toFixed(2), //Math.round(this.total).toFixed(2),
                                          "fontSize":12,
                                          "color": this.primaryColor,

                                          "bold":true,
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          // "width": "100",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ]
                                  
                                ],
                                "widths": [
                                  "100%"
                                ],
                                "heights": [
                                  "auto",
                                  "auto",
                                  "auto",
                                  "auto"
                                ]
                              }
                            },
                            // {
                            //   "text": "DISCOUNT : 0",
                            //   "nodeName": "P",
                            //   "margin": [
                            //     0,
                            //     5,
                            //     0,
                            //     1
                            //   ],
                            //   "style": [
                            //     "html-p",
                            //     "html-td",
                            //     "html-tr",
                            //     "html-tbody",
                            //     "html-table",
                            //     "html-div",
                            //     "invoice",
                            //     "mb-5",
                            //     "invoiceDes2",
                            //     "table-responsive",
                            //     "table",
                            //     "table-striped"
                            //   ],
          
                            // },
                            // {
                            //   "text": "CGST : "  + (this.totalBeforTaxAmount* this.CGST/100).toFixed(2), 
                            //   "nodeName": "P",
                            //   "margin": [
                            //     0,
                            //     5,
                            //     0,
                            //     1
                            //   ],
                            //   "style": [
                            //     "html-p",
                            //     "html-td",
                            //     "html-tr",
                            //     "html-tbody",
                            //     "html-table",
                            //     "html-div",
                            //     "invoice",
                            //     "mb-5",
                            //     "invoiceDes2",
                            //     "table-responsive",
                            //     "table",
                            //     "table-striped"
                            //   ]
                            // },
                            // {
                            //   "text": "SGST : "+ (this.totalBeforTaxAmount * this.SGST/100).toFixed(2),
                            //   "nodeName": "P",
                            //   "margin": [
                            //     0,
                            //     5,
                            //     0,
                            //     1
                            //   ],
                            //   "style": [
                            //     "html-p",
                            //     "html-td",
                            //     "html-tr",
                            //     "html-tbody",
                            //     "html-table",
                            //     "html-div",
                            //     "invoice",
                            //     "mb-5",
                            //     "invoiceDes2",
                            //     "table-responsive",
                            //     "table",
                            //     "table-striped"
                            //   ]
                            // },
                            // {
                            //   "text": "IGST : "+ (this.totalBeforTaxAmount * this.IGST/100).toFixed(2),
                            //   "nodeName": "P",
                            //   "margin": [
                            //     0,
                            //     5,
                            //     0,
                            //     1
                            //   ],
                            //   "style": [
                            //     "html-p",
                            //     "html-td",
                            //     "html-tr",
                            //     "html-tbody",
                            //     "html-table",
                            //     "html-div",
                            //     "invoice",
                            //     "mb-5",
                            //     "invoiceDes2",
                            //     "table-responsive",
                            //     "table",
                            //     "table-striped"
                            //   ]
                            // },
                          
                          ],
                          "colSpan": 1,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        }
                      ]
                    ],
                    "widths": [
                      "20%",
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      "10%",
                      "10%",
                      "10%",
                      "33%"
                    ],
                    "heights": [
                      "auto",
                      "auto",
                      "auto",
                      "auto"
                    ]
                  },
                  "marginBottom": 5,
                  "style": [
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                }
              ]
            },

            {
              "nodeName": "TABLE",
              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "margin": [
                        -5,
                        5,
                        -5,
                        0
                      ],
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          "stack": [
                            {
                              "text": "We declared that this invoice shows the actual price of the goods describe and all particulars are true and correct.",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Goods Ones sold will not be taken back",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " No Claim Of Brekage",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Intrest will Be Charged @18% p.a if payment is not made within stipulated time",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Subject To Indore Jurisdiction",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                1,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "70%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "alignment": "right",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          "stack": [
                            {
                              "text": "For Jai Bharat Elec. Co.",
                              "nodeName": "P",
                              "bold":true,
                              "color":this.primaryColor,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": "\n",
                              "nodeName": "BR"
                            },
                            {
                              "text": "\n",
                              "nodeName": "BR"
                            },
                            {
                              "text": "  Authorised Signatury",
                              "nodeName": "P",
                              "margin": [
                                0,
                                5,
                                0,
                                10
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "30%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "70%",
                  "30%"
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 5,
              "style": [
                "html-table"
              ]
            }
          ]
        }
      ],

      defaultStyle: {
        // font: 'yourFontName',
        Roboto: {
          normal:'https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap',// 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        },
      }
    }

    pdfMake.fonts = {
      
   
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
      },
   
      // example of usage fonts in collection
      // PingFangSC: {
      //   normal: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Regular'],
      //   bold: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Semibold'],
      // }
   }
    pdfMake.createPdf(documentDefinition).open();
    // pdfMake.createPdf(documentDefinition).download('"Invoice".pdf');
    
  
  }


  
  
  getPdfHSNTableData(InvoiceHSNTableData:any){
    return{
      "nodeName": "DIV",
      "stack": [
        {
          "nodeName": "TABLE",
          "marginBottom": 5,
          "width": "100%",
          "fontSize": 10,
          "style": [
            "html-table",
            "html-div",
            "table",
            "table-striped"
          ],
          "table": {
            "body": [
              [
                {
                  "text": "HSN CODE",
                  "nodeName": "TH",
                  "bold": true,
                  "border":[false,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor":  this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "QTY",
                  "nodeName": "TH",
                  "bold": true,
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "TAXRATE",
                  "nodeName": "TH",
                  "bold": true,
                  // "border":[true,true,true,true],
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "TAXABLE VALUE",
                  "nodeName": "TH",
                  "bold": true,
                  // "border":[true,true,true,true],
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "SGST",
                  "nodeName": "TH",
                  "bold": true,
                  // "border":[true,true,true,true],
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "CGST",
                  "nodeName": "TH",
                  "bold": true,
                  // "border":[true,true,true,true],
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "IGST",
                  "nodeName": "TH",
                  "border":[true,true,true,true],
                  "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  "bold": true,
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "TOTAL TAX",
                  "nodeName": "TH",
                  "border":[true,true,false,true],
                  "borderColor":["#fff","#fff",this.primaryColor,this.primaryColor],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                },
              ],
              ...InvoiceHSNTableData.map((e, index) => {
                console.log('e', e)
                delete e.totalsum;
                console.log('after', e)


                return [ 
                  {
                    text:e.HsnCode,
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text:e.qty,
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text:e.taxRate,
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text:(e?.taxableValue).toFixed(2),
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text:(e?.SGST ||0).toFixed(2),
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text:(e?.CGST ||0).toFixed(2),
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },

                  {
                    text:(e?.IGST || 0).toFixed(2),
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },
                  {
                    text: (e?.totalTax ||0).toFixed(2),
                    "border":[true,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  },



                  // e.HsnCode,
                  // e.qty,
                  // e.taxRate,
                  // e.taxableValue.toFixed(2),
                  // e.SGST.toFixed(2),
                  // e.CGST.toFixed(2),
                  // e.IGST.toFixed(2),
                  // e.totalTax.toFixed(2)
                ];
              }),
            ],
            "fontSize": 8,
            "widths": [
              "26%", "6%", "10%",
              "12%",
              "10%","10%",
              "10%","15%"
              

            ],
            "heights": [
              "auto",
              "auto",
              "auto",
              "auto"
            ]
          }
        }
        ]
    }
  }

  getPdfTableData(invoiceTableData: any) {
    return {

      "nodeName": "DIV",
      "stack": [
        {
          "nodeName": "TABLE",
          "marginBottom": 5,
          "width": "auto",
          "borderColor":['#fff','#fff','#fff','#fff',],
          "fontSize": 10,
          "style": [
            "html-table",
            "html-div",
            "table",
            "table-striped"
          ],
          "table": {
            "body": [
              [
                {
                  "text": "#",
                  "nodeName": "TH",
                  "bold": true,
                  "border":[false,true,false,true],
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Product Name",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  // "color":"#01139b",
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "HSN",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Qty",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Free Qty",
                  "nodeName": "TH",
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "bold": true,
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },

                {
                  "text": "Unit Rate",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Sale Rate",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },

                {
                  "text": "Sale Disc.(₹) ",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "T.Disc.%",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Trade Disc.(₹)",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Total Disc.(₹)",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
               
                {
                  "text": "Taxable Amt.",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff','#01139b',],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                // {
                //   "text": "CGST%",
                //   "nodeName": "TH",
                //   "bold": true,
                //   "borderColor":['#fff','#fff','#fff','#01139b',],
                //   "fillColor": this.secColor,
                //   "style": [
                //     "html-th",
                //     "html-tr",
                //     "html-thead",
                //     "html-table",
                //     "html-div",
                //     "table",
                //     "table-striped"
                //   ]
                // },
                // {
                //   "text": "SGST%",
                //   "nodeName": "TH",
                //   "bold": true,
                //   "borderColor":['#fff','#fff','#fff','#01139b',],
                //   "fillColor": this.secColor,
                //   "style": [
                //     "html-th",
                //     "html-tr",
                //     "html-thead",
                //     "html-table",
                //     "html-div",
                //     "table",
                //     "table-striped"
                //   ]
                // },
                {
                  "text": "GST%",
                  "nodeName": "TH",
                  "bold": true,
                  "border":[true,true,true,true],
                  "borderColor":['#fff','#fff','#fff',this.primaryColor,],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "Total",
                  "nodeName": "TH",
                  "bold": true,
                  "borderColor":['#fff','#fff','#fff',this.primaryColor,],
                  "fillColor": this.secColor,
                  "style": [
                    "html-th",
                    "html-tr",
                    "html-thead",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
              ],
              ...invoiceTableData.map((e, index) => {
                console.log('invoiceTableData e', invoiceTableData)
                return [
                 {
                  text: index + 1,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                 },
                  {
                    text:e.productName,
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text:e.hsnCode,
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text: e.dispatchQuantity, 
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text: e?.freeNoOfItem,
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text: e?.orderUnitPrice.toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text:  e?.orderAfter_tax_product_amount.toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text:   (e?.orderScheme_discount_amount* Number(e.dispatchQuantity)).toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   {
                    text:   e?.orderTrade_discount_per,
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   //trade discount Amount
                   {
                    text:  (e.orderT_discount * Number(e.dispatchQuantity)).toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   // total discount
                   {
                    text:  (Number(e.orderTotal_discount) * Number(e.dispatchQuantity)).toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                  // taxable value with qty
                   {
                    text:(e.orde_sales_price * e.dispatchQuantity).toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                  //  {
                  //   text:e.gstItem.SGST,
                  //   "border":[false,true,true,true],
                  //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  //  },
                  //  {
                  //   text:e.gstItem.CGST,
                  //   "border":[false,true,true,true],
                  //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  //  },
                  // gst %
                   {
                    text:e.orderTax_per,
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                   // after tax * qty
                   {
                    text:(e.orderAfter_tax_product_amount * e.dispatchQuantity).toFixed(2),
                    "border":[false,true,true,true],
                    "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                   },
                  //  index + 1,
                  //  e.productName,
                  // e.hsnCode,
                  // e.dispatchQuantity,
                  // e.freeNoOfItem ,
                  // e.beforTaxRate.toFixed(2),

                  // e.afterTaxRate.toFixed(2),
                  // e.saleDiscount, 
                  // e.discountPer,
                  // 0,
                  // (e.afterTaxRate * Number(e.dispatchQuantity)).toFixed(2),

                  // (e.beforTaxRate * Number(e.dispatchQuantity)).toFixed(2),
                  // e.gstItem.SGST,
                  // e.gstItem.CGST,
                  // e.gstItem.IGST
                ];
              }),


              [
                {
                  "text": "Total",
                  "nodeName": "TD",
                  "border":[true,true,true,true],
                  "bold":true,
                  "alignment":"right",
                  "marginTop":1,
                  "marginRight":2,
                  "marginBottom":2,
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped",
                    "text-end"
                  ],
                  "colSpan": 3
                },
                {
                  "text": "",
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.primaryColor,this.tbColor,this.tbColor],
                },
                {
                  "text": "",
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                },
                {
                  "text": this.totalQty,
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": this.totalFreeQty,
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped",
                    "text-end"
                  ],

                },
                {
                  "text": this.totalBeforeRate.toFixed(2),
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
               

                {
                  "text": this.totalAfterRate.toFixed(2),
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "",
                  "nodeName": "TD",
                  "border":[true,true,true,true],
                  "bold":true,
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                // {
                //   "text": "0",
                //   "nodeName": "TD",
                //   "border":[true,true,true,true],
                //   "bold":true,
                //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                //   "style": [
                //     "html-td",
                //     "html-tr",
                //     "html-tfoot",
                //     "html-table",
                //     "html-div",
                //     "table",
                //     "table-striped"
                //   ]
                // },
                {
                  "text": "",
                  "nodeName": "TD",
                  "border":[true,true,true,true],
                  "bold":true,
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "",
                  "nodeName": "TD",
                  "border":[true,true,true,true],
                  "bold":true,
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": this.totalTrader_SchemeDiscount.toFixed(2) || 0,
                  "nodeName": "TD",
                  "border":[true,true,true,true],
                  "bold":true,
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": this.totalTaxableAmount.toFixed(2) ||0,
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": (this.totalTaxAmount).toFixed(2) ,//this.totalBeforTaxAmount.toFixed(2),
                  "bold":true,
                  "border":[true,,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                // {
                //   "text": (this.totalBeforTaxAmount * this.CGST/100).toFixed(2),
                //   "bold":true,
                //   "nodeName": "TD",
                //   "border":[true,true,true,true],
                //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                //   "style": [
                //     "html-td",
                //     "html-tr",
                //     "html-tfoot",
                //     "html-table",
                //     "html-div",
                //     "table",
                //     "table-striped"
                //   ]
                // },
                // {
                //   "text":(this.totalBeforTaxAmount * this.SGST/100).toFixed(2) ,
                //    "bold":true,
                //   "border":[true,true,true,true],
                //   "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                //   "nodeName": "TD",
                //   "style": [
                //     "html-td",
                //     "html-tr",
                //     "html-tfoot",
                //     "html-table",
                //     "html-div",
                //     "table",
                //     "table-striped"
                //   ]
                // },
                {
                  "text":(this.totalAfterTaxAmount).toFixed(2),//(this.totalBeforTaxAmount * this.IGST/100).toFixed(2) ,
                  "bold":true,
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                  "nodeName": "TD",
                  "style": [
                    "html-td",
                    "html-tr",
                    "html-tfoot",
                    "html-table",
                    "html-div",
                    "table",
                    "table-striped"
                  ]
                },
                {
                  "text": "",
                  "border":[true,true,true,true],
                  "borderColor":[this.tbColor,this.tbColor,this.tbColor,this.tbColor],
                },
               

              ]
            ],
            "fontSize": 8,
            "widths": [
              "3%", "23%", "7%",
              "5%",
              "3%",
              "8%",
              "8%",
              "6.5%",
              "5%",
              "5%", 
              "8%",
              "8%",
              "6%",
              "8%",
              "5%",
              "6%",
              "10%",
             

            ],
            "heights": [
              "auto",
              "auto",
              "auto",
              "auto"
            ]
          }
        }
      ]


    }


  }

  generatePosPdf() {
    const documentDefinition = {
      pageSize: 'A4',
      pageMargins: [25, 25, 25, 25],
      "content": [
        {
          "nodeName": "DIV",
          "id": "demo",
          "stack": [

            //new header
            {
              "nodeName": "TABLE",
              "fontSize": 11,
              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "text": "Jai Bharat Elec. Co.",
                          "nodeName": "H1",
                          "fontSize": 16,
                          "bold": true,
                          "marginBottom": 2,
                          "style": [
                            "html-h1",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "text-start"
                          ]
                        },
                        {
                          "text": "2-Jail Road Indore-452001",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Phone No. : 0731-2534606,",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": " E-Mail : jaibharatindore@gmail.com",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "GST No.:- 23BBPPK3343D1ZX",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            0
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "text-start",
                            "mt-3"
                          ]
                        }
                      ],
                      "width": "50%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          "stack": [
                            {
                              "text": "Sales Tax Invoice",
                              "nodeName": "H1",
                              "alignment": "right",
                              "fontSize": 16,
                              "bold": true,
                              "marginBottom": 2,
                              "style": [
                                "html-h1",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "text-end"
                              ]
                            },
                            {
                              "text": "Invoice No." + this.invoiceDetail.id,
                              "nodeName": "P",
                              "alignment": "right",
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": "Invoice Date : " + this.datePipe.transform(this.invoiceDetail.createdDate, 'dd/MM/yyyy'),
                              "nodeName": "P",
                              "alignment": "right",
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "50%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "50%",
                  "50%"
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 5,
              "style": [
                "html-table"
              ]
            },

            {
              "nodeName": "TABLE",
              "fontSize": 11,
              "width": "100%",

              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "text": "Name & Billing Address of Consignee :",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": this.invoiceDetail.customerName,
                          "nodeName": "H2",
                          "fontSize": 16,
                          "bold": true,
                          "marginBottom": 2,
                          "style": [
                            "html-h2",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ],
                        },
                        {
                          "text": this.invoiceDetail.address,
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Phone : " + this.invoiceDetail.mobile,
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "State Name : " + this.invoiceDetail.stateName,
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },

                        {
                          "text": "GSTIN : " + this.invoiceDetail.gstNumber,
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "DL No. : ",
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        }
                      ],
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "text": [
                            {
                              "text": "Customer Type :",
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": this.invoiceDetail.userType,
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],
                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        // {
                        //   "text": "Invoice No. DG/21-22/1531  ",
                        //   "nodeName": "H4",
                        //   "fontSize": 12,
                        //   "bold": true,
                        //   "marginBottom": 5,
                        //   "style": [
                        //     "html-h4",
                        //     "html-td",
                        //     "html-tr",
                        //     "html-tbody",
                        //     "html-table"
                        //   ]
                        // },

                        {
                          "text": "\n",
                          "nodeName": "BR"
                        },
                        {
                          "text": "Order No. : # " + this.invoiceDetail.orderId,
                          "nodeName": "H4",
                          "fontSize": 10,
                          "bold": true,
                          "marginBottom": 5,
                          "style": [
                            "html-h4",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": "Order By :  " + this.invoiceDetail.orderNameStatus,
                          "nodeName": "H4",
                          "fontSize": 10,
                          "bold": false,
                          "marginBottom": 5,
                          "style": [
                            "html-h4",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": [
                            {
                              "text": "Order Date :",
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },

                            {
                              "text": this.datePipe.transform(this.invoiceDetail.orderDate, 'dd/MM/yyyy'),
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],

                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        },
                        {
                          "text": [
                            {
                              "text": "Order Type :",
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },

                            {
                              "text":this.invoiceDetail.orderTypeStatus,
                              "nodeName": "SPAN",
                              "style": [
                                "html-span",
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ],

                          "nodeName": "P",
                          "margin": [
                            0,
                            5,
                            0,
                            1
                          ],
                          "style": [
                            "html-p",
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table"
                          ]
                        }
                      ],
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "auto",
                  "auto",
                  145
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 5,
              "style": [
                "html-table"
              ]
            },
            this.getPdfTableData(this.tableArray),
            this.getPdfHSNTableData(this.invoiceHsnTable),
            {
              "nodeName": "DIV",
              "fontSize": 10,
              "stack": [
                {
                  "nodeName": "TABLE",
                  "width": "100%",
                  "border":[false,false,false,false],
                  "table": {
                    "width": "100%",
                  //   "border":[false,true,true,true],
                  // "borderColor":["#fff","#fff","#fff",this.primaryColor],
                  // "fillColor":  this.secColor,
                    "body": [
                      [
                        {
                          "text": "Freight",
                          // "border":[false,false,false,false],
                            // "border":[false,true,true,true],
                            //   "borderColor":[this.primaryColor,this.primaryColor,this.primaryColor,this.primaryColor],
                            // "fillColor":  this.secColor,
                          "nodeName": "TD",
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped",
                            "text-end"
                          ],
                          "colSpan": 3
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": "0.00",
                          "nodeName": "TD",
                          "colSpan": 3,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": "GROSS AMOUNT",
                          "nodeName": "TD",
                          "alignment": "right",
                          "fontSize": 12,
                          "bold": true,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped",
                            "text-end"
                          ],
                          "colSpan": 2
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": Math.round(this.total).toFixed(2),
                          "nodeName": "TD",
                          "alignment": "right",
                          // "background":"yellow",
                          "fontSize": 12,
                          "bold": true,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped",
                            "text-end"
                          ]
                        }
                      ],
                      [
                        {
                          "nodeName": "TD",
                           "width": "100%",

                          "stack": [
                            {
                              "text": "Amt.of Tax Reverse Charges",
                              "nodeName": "P",
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "text": "Rupees : " +  this.amountIntoWords + " Only",                      
                               "nodeName": "P",
                               "fontSize":12,
                              //  "background":"yellow",
                               "bold": true,
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "text": "Note :",
                              "nodeName": "P",
                              "margin": [
                                0,
                                5,
                                0,
                                1
                              ],
                              "style": [
                                "html-p",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "html-div",
                                "invoice",
                                "mb-5",
                                "invoiceDes2",
                                "table-responsive",
                                "table",
                                "table-striped"
                              ]
                            },
                            {
                              "nodeName": "DIV",
                              "stack": [
                                {
                                  "text": "Jai Bharat Electric Co.",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    5,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },
                                {
                                  "text": "Axis Bank",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    5,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },

                                {
                                  "text": "Branch: Sapna Sangita Road,Indore",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    5,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                },
                                {
                                  "text": "Current Account: 503010200003414, IFSC Code: UTIB0000503",
                                  "nodeName": "P",
                                  "margin": [
                                    0,
                                    5,
                                    0,
                                    1
                                  ],
                                  "style": [
                                    "html-p",
                                    "html-td",
                                    "html-tr",
                                    "html-tbody",
                                    "html-table",
                                    "html-div",
                                    "invoice",
                                    "mb-5",
                                    "invoiceDes2",
                                    "table-responsive",
                                    "table",
                                    "table-striped",
                                    "bank-detail"
                                  ]
                                }
                              ]
                            }
                          ],
                          "colSpan": 8,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "text": ""
                        },
                        {
                          "nodeName": "TD",
                          "fontSize":12,
                          "bold": true,
                          "alignment": "right",

                          "stack": [
                            {
                              "nodeName": "TABLE",
                              "marginBottom": 0,
                              "marginTop": 0,
                              "marginLeft": 0,
                              "marginRight": 0,
                              "border": [
                                true,
                                false,
                                false,
                                false
                              ],
                              "style": [
                                "html-table",
                                "html-div"
                              ],
                              "table": {
                                "border": [
                                  false,
                                  false,
                                  false,
                                  false
                                ],
                                "body": [
                                  [
                                    
                                    
                                    {
                                      "text": [
                                      

                                        {
                                          "text": "Discount",
                                          "nodeName": "SPAN",
                                          // "background": "yellow",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "float": "right",
                                          // "background": "yellow",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": "0.00",
                                          "nodeName": "SPAN",
                                          // "background": "red",
                                          "float": "right",
                                          "alignment":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "alignment": "right",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        {
                                          "text": "CGST",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.totalBeforTaxAmount* this.CGST/100).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "alignment": "right",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        {
                                          "text": "SGST",
                                          "nodeName": "SPAN",
                                          "fontSize":12,
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.totalBeforTaxAmount * this.SGST/100).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "alignment": "right",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ],
                                  [
                                    {
                                      "text": [
                                        {
                                          "text": "IGST",
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"left",
                                          "width": 80,
                                          // "color": "red",
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": " : ",
                                          "alignment": "right",
                                          "border": [
                                            false,
                                            false,
                                            false,
                                            false
                                          ],
                                          "style": [
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        },
                                        {
                                          "text": (this.totalBeforTaxAmount * this.IGST/100).toFixed(2),
                                          "nodeName": "SPAN",
                                          "alignment": "right",
                                          "float":"right",
                                          "width": 80,
                                          "style": [
                                            "html-span",
                                            "html-td",
                                            "html-tr",
                                            "html-tbody",
                                            "html-table",
                                            "html-div"
                                          ]
                                        }
                                      ],
                                      "nodeName": "TD",
                                      "border": [
                                        false,
                                        false,
                                        false,
                                        false
                                      ],
                                      "alignment": "right",
                                      "style": [
                                        "html-td",
                                        "html-tr",
                                        "html-tbody",
                                        "html-table",
                                        "html-div"
                                      ]
                                    }
                                  ]
                                ],
                                "widths": [
                                  104
                                ],
                                "heights": [
                                  "auto",
                                  "auto",
                                  "auto",
                                  "auto"
                                ]
                              }
                            },
                            // {
                          
                          
                          ],
                          "colSpan": 1,
                          "style": [
                            "html-td",
                            "html-tr",
                            "html-tbody",
                            "html-table",
                            "html-div",
                            "invoice",
                            "mb-5",
                            "invoiceDes2",
                            "table-responsive",
                            "table",
                            "table-striped"
                          ]
                        }
                      ]
                    ],
                    "widths": [
                      100,
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      "auto",
                      60,
                      60,
                      110
                    ],
                    "heights": [
                      "auto",
                      "auto",
                      "auto",
                      "auto"
                    ]
                  },
                  "marginBottom": 5,
                  "style": [
                    "html-table",
                    "html-div",
                    "invoice",
                    "mb-5",
                    "invoiceDes2",
                    "table-responsive",
                    "table",
                    "table-striped"
                  ]
                }
              ]
            },

            {
              "nodeName": "TABLE",
              "table": {
                "body": [
                  [
                    {
                      "nodeName": "TD",
                      "margin": [
                        -5,
                        5,
                        -5,
                        0
                      ],
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          "stack": [
                            {
                              "text": "We declared that this invoice shows the actual price of the goods describe and all particulars are true and correct.",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Goods Ones sold will not be taken back",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " No Claim Of Brekage",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Intrest will Be Charged @18% p.a if payment is not made within stipulated time",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            },
                            {
                              "text": " Subject To Indore Jurisdiction",
                              "nodeName": "P",
                              "fontSize": 8,
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table",
                                "term"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "70%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    },
                    {
                      "nodeName": "TD",
                      "alignment": "right",
                      "border": [
                        false,
                        false,
                        false,
                        false
                      ],
                      "stack": [
                        {
                          "nodeName": "DIV",
                          "stack": [
                            {
                              "text": "For Jai Bharat Elec. Co.",
                              "nodeName": "P",
                              "margin": [
                                0,
                                5,
                                0,
                                0
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            },
                            {
                              "text": "\n",
                              "nodeName": "BR"
                            },
                            {
                              "text": "\n",
                              "nodeName": "BR"
                            },
                            {
                              "text": "  Authorised Signatury",
                              "nodeName": "P",
                              "margin": [
                                0,
                                5,
                                0,
                                10
                              ],
                              "style": [
                                "html-p",
                                "html-div",
                                "html-td",
                                "html-tr",
                                "html-tbody",
                                "html-table"
                              ]
                            }
                          ]
                        }
                      ],
                      "width": "30%",
                      "style": [
                        "html-td",
                        "html-tr",
                        "html-tbody",
                        "html-table"
                      ]
                    }
                  ]
                ],
                "widths": [
                  "70%",
                  "30%"
                ],
                "heights": [
                  "auto"
                ]
              },
              "marginBottom": 5,
              "style": [
                "html-table"
              ]
            }
          ]
        }
      ],

    }

    pdfMake.createPdf(documentDefinition).open();

    
  }

  }


