import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IGetShopTable } from 'src/app/core/interfaces/masters/shop/get-shopTable-list';
import { IGetShopWiseTable } from 'src/app/core/interfaces/order/order-management/get-shopWiseTable';
import { IGetCustomerOrderDetails } from 'src/app/core/interfaces/order/order-allocation/get-customerOrderDetails';
import { InvoiceModel } from 'src/app/core/models/order/order-management/invoice-master-model';
import { IGetInvoiceDetail } from 'src/app/core/interfaces/order/order-management/get-invoiceDetail';
import { DeleteInvoiceLineItemsModel } from 'src/app/core/models/order/order-management/delete-invoice-lineItems-model';
import { IGetOrderAllocationTable } from 'src/app/core/interfaces/order/order-allocation/get-order-allocation-table';
import { IGetTranspoter } from 'src/app/core/interfaces/masters/transpoter/get-transpoter';
import { ITranspoterDetailsModel } from 'src/app/core/models/order/order-management/transpoter-detail/transopter-detail-model';
import { IGetTranspoterDetail } from 'src/app/core/interfaces/order/order-management/transpoter-detail/get-transpoterDetails';
import { ModelChangesChallanStatus } from 'src/app/core/models/order/order-management/change-status-challan-model';


@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

  constructor(private http: HttpClient) { }
  serverEndPoint = environment.apiUrl;

  getShopList(mainshopId: Number,shopId: Number,statusEnumId:Number): Observable<IGetShopTable> {
    const url = this.serverEndPoint + 'getSubShopList?mainShopId='+ mainshopId + '&shopId='+ shopId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetShopTable>(url);
  }

  getCustomeOrderDetails(orderId: Number,statusEnumId:Number,shopId:Number): Observable<IGetCustomerOrderDetails> {
    const url = this.serverEndPoint +'getCustomerOrderAndDetailForShop?orderId='+ orderId +
     '&statusEnumId=' + statusEnumId + '&shopId=' + shopId  ;
    return this.http.get<IGetCustomerOrderDetails>(url);
  }

 

  getShopWiseTableData(orderId: number,statusEnumId:Number,lineItemOrderStatusEnumId:number,shopId:Number): Observable<IGetShopWiseTable> {
    const url = this.serverEndPoint + 'getCustomerOrderForShopTable?orderId='+orderId + '&statusEnumId='+ statusEnumId + 
    '&lineItemOrderStatusEnumId=' + lineItemOrderStatusEnumId + '&shopId=' + shopId 
    return this.http.get<IGetShopWiseTable>(url);
  }

  getInvoiceDetailData(invoiceId:Number,statusEnumId:Number,invoiceStatusEnumId:Number,shopId=Number):Observable<IGetInvoiceDetail>{
    const url = this.serverEndPoint + 'getInvoiceOrderDetail?invoiceId='+ invoiceId +'&statusEnumId='+ 
    statusEnumId +'&invoiceStatusEnumId='+ invoiceStatusEnumId +'&shopId=' + shopId 
    return this.http.get<IGetInvoiceDetail>(url);
 
  }

  getInvoiceList(limit:Number,page:number,invoiceId:Number,statusEnumId:Number,invoiceStatusEnumId:Number,shopId=Number):Observable<IGetInvoiceDetail>{
    const url = this.serverEndPoint + 'getInvoiceOrderDetailList?limit='+limit+'&page='+page+'&invoiceId='+ invoiceId +'&statusEnumId='+ 
    statusEnumId +'&invoiceStatusEnumId='+ invoiceStatusEnumId +'&shopId=' + shopId 
    return this.http.get<IGetInvoiceDetail>(url);
 
  }

  // getInvoiceOrderDetailList?limit=1000&page=1&invoiceId=0&statusEnumId=0&invoiceStatusEnumId=66&shopId=0
  
  getInvoiceTableData(invoiceId:Number,statusEnumId:Number,invoiceStatusEnumId:Number,shopId=Number):Observable<IGetInvoiceDetail>{
    const url = this.serverEndPoint + 'getInvoiceTable?invoiceId='+ invoiceId +'&statusEnumId='+ 
    statusEnumId +'&invoiceStatusEnumId='+ invoiceStatusEnumId +'&shopId=' + shopId 
    return this.http.get<IGetInvoiceDetail>(url);
 
  }


  invoiceOrder(invoiceModel: InvoiceModel): Observable<any> {
    const url = this.serverEndPoint + 'addUpdateInvoice';
    console.log(url,',odel',invoiceModel)
    return this.http.post<any>(url, invoiceModel)
  }

  checkItemForPacking(obj: any): Observable<any> {
    const url = this.serverEndPoint + 'changeStatusOfPackingOrderItem';
    console.log(url,',odel',obj)
    return this.http.post<any>(url, obj)
  }
  // {
  //   "orderId": "2556",
  //   "product_id":"8493"
  //   }

  

  addcompletInvoiceDetail(addcompletInvoiceModel: InvoiceModel): Observable<any> {
    const url = this.serverEndPoint + 'addcompletInvoiceDetail';
    console.log(url,',odel', addcompletInvoiceModel)
    return this.http.post<any>(url, addcompletInvoiceModel)
  }

  deleteInvoiceLineItems(deleteInvoiceLineModel: DeleteInvoiceLineItemsModel): Observable<any> {
    const url = this.serverEndPoint + 'deleteLineItemDetailToPendingInvoice';
    console.log(url,',odel',deleteInvoiceLineModel)
    return this.http.post<any>(url, deleteInvoiceLineModel)
  }
  getOrderUnALLocationList(orderId: Number,orderStatusEnumId:number,statusEnumId:number): Observable<IGetOrderAllocationTable> {
    const url = this.serverEndPoint +'getCustomerOrderForAdminTable?orderId='+ orderId + '&orderStatusEnumId=' + orderStatusEnumId + '&statusEnumId=' + statusEnumId;
    return this.http.get<IGetOrderAllocationTable>(url);
  }

  addUpdateTranspoter(transpoterDetailmodel: ITranspoterDetailsModel): Observable<any> {
    const url = this.serverEndPoint + 'addUpdateDispatch';
    console.log(url,',odel', transpoterDetailmodel)
    return this.http.post<any>(url, transpoterDetailmodel)
  }
  getTranspoterData(id: Number): Observable<IGetTranspoter> {
    const url = this.serverEndPoint + 'getTransportDetail?transportId='+ id ;
    return this.http.get<IGetTranspoter>(url);
  }

  
  getTranspoterDetail(dispatchId:Number,dispatchStatusEnumId:Number,shopId=Number):Observable<IGetTranspoterDetail>{
    const url = this.serverEndPoint +'getDispatchList?dispatchId='  +dispatchId + '&statusEnumId=1 &dispatchStatusEnumId=' +dispatchStatusEnumId + '&shopId=' + shopId
    return this.http.get<IGetTranspoterDetail>(url);
 
  }


  // change challan status in invoice pendding 

  changeChallanStatus(challanStatusModel: ModelChangesChallanStatus): Observable<any> {
    const url = this.serverEndPoint + 'changeStatusOfChallan';
    console.log(url,',odel',challanStatusModel)
    return this.http.post<any>(url, challanStatusModel)
  }

 

}
