//===============================================================================
// © 2021 .Kritin Digital solutions  All rights reserved.
// Original Author: Aman Mishra
// Original Date: 7 June 2021
//@Desc: Http error handling intercept
//==============================================================================

import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(retry(0),catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.error.MessageList}`;
          } else {
            // server-side error
            console.log('error',error.error)
            errorMessage = error?.error?.message == undefined ?
             `Server Disconnected. \r\n
              Sorry for the inconvenience. \r\n 
              Please Try Again Later`:error?.error?.message 
            //  `We Are Unable To Process Your Request Please Try Again Later \nView Error Details Below: \nError Code: ${error?.error?.statusCode== undefined ? 'Server Disconnected':error?.error?.statusCode} \nMessage: ${error?.error?.message == undefined ? 'Server Disconnected':error?.error?.message }`;
          }
          // window.alert(errorMessage);
          this.toastr.error(errorMessage)
          return throwError(errorMessage);
        }))
  }
}
