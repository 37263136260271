import { ICustomerProfile } from "src/app/core/interfaces/order/order-management/sales-order/customer-profile";

export class CustomerProfile implements  ICustomerProfile {
    userId: number;
    customerName: string;
    contactPersonName: string;
    emailId: string;
    dateOfBirth: string;
    genderEnumId: number;
    stateId: number;
    cityId: number;
    mobileNumber:Number;
    address:string;
    statusEnumId = 1;
    referralCode : string;
    whatsAppNo: number;
    mobileNo:number;
    pinCode:number;
    refer_by_1 : string;
    refer_by_2 : string;
   
    refer_by_3 : string;
    refer_by_4 : string;
    gstNumber:any;
    customerType:string
    
 }