
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../interfaces/common/common-response';
import { IHistoryData } from '../interfaces/common/history-response';
import { ToastrService } from 'ngx-toastr';
import { NUMBER_TYPE } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private http: HttpClient, private toastr : ToastrService) { }
  serverEndPoint = environment.apiUrl;


  uploadImage(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const url = this.serverEndPoint + 'fileUpload';
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  deleteImage(fileName): Observable<ICommonResponse> {
    const url = this.serverEndPoint + 'fileDelete';
    return this.http.post<ICommonResponse>(url, fileName)
  }
  getHistoryDetail(masterName: string, masterId: Number): Observable<IHistoryData> {
    const url = this.serverEndPoint + 'getMasterRemarkByMasterNameAndMasterId?masterName=' + masterName + '&mastersId=' + masterId;
    return this.http.get<IHistoryData>(url)
  }

  getDateFormat(data) {
    const date = new Date(data)
    console.log('date', date)
    return date;
    // return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  }
 
  // getDateFormat(date) {
  //   return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  // }

  convertYear(date:Date) {
    const formattedDate =  date.getFullYear();
    return formattedDate
  }

  
  convertDate(date: any) {
    if (date ===  undefined){
      date = '';
    }
    if (date !== ''){
      if (date !== null && typeof(date) !== 'string') {
        if (date._d !== undefined){
          date =  date._d;
        }
        const formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
        return formattedDate;
      }
    }
  }
  //  convertDate(date:Date) {
  //    console.log('date', date)
  //   // const formattedDate =  date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  //    return ""  
  // }

  checkUserAccessControlForNavBar(page) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let flag = false;
    if(userAccessData.find(x => x.module == page).isSelected === true) {
      flag = true;
    }
    return flag;
  }
  checkUserAccessControlForNavItem(page, item) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let flag = false;
    let pageData = userAccessData.find(x => x.module == page).data;
    let itemData = pageData.find(x => x.page_name == item);
    if(itemData.add || itemData.view || itemData.edit || itemData.delete) {
      flag = true;
    }
    return flag;
  }
  checkUserAccessControlForAction(module, page, action) {
    let userAccessData = JSON.parse(sessionStorage.getItem('userAccessData'));
    if(userAccessData == null) {
      return true;
    }
    let pageData = userAccessData.find(x => x.module == module).data;
    let itemData = pageData.find(x => x.page_name == page);
    return itemData[action]

  }

  
  
  getEnumType(Type: string): Observable<any> {
    const url = this.serverEndPoint + 'GetEnumDetail?Enum_type=' + Type ;
    return this.http.get<any>(url)
  }


  // CHECK MRP IS GREATER
  checkMRPIsSmall(value:any) {
    let MRP: any = Number(value?.enterMrp)
    
    let isSmall:any =false
    // console.log(value, MRP > value.afterTaxRate, MRP > value.distributionAfterTaxRate)
    // console.log(MRP , value.distributionAfterTaxRate,value.afterTaxRate)
    // check all parameter
    if((MRP > Number(value.afterTaxRate) ) &&  (MRP > Number(value.distributionAfterTaxRate))) {
     isSmall=true

    }    
    else{

     this.toastr.warning(MRP,"MPR Must be High" )
      isSmall=false
    }
    return isSmall
  }

  convertNumberToWords(amount) {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j] as any);
            n_array[i] = 0;
          }
        }
      }
      let value;
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Crores ";
        }
        if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Lakhs ";
        }
        if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
          words_string += "Thousand ";
        }
        if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string + 'Rupees';
  }
}
