import { ISaveOrderDetail } from "src/app/core/interfaces/order/order-management/sales-order/save-order-detail";
import { Quotation } from "src/app/core/interfaces/quotation/add-quotation";

export class SaveSalesOrderDetail implements ISaveOrderDetail {
    orderId: number;
    customerId: number;
    subTotal: number;
    cartId: number;
    discountAmount: number;
    orderDate: string;
    totalTax: number;
    taxAmount: number;
    otherCharges: number;
    couponCodeOrGiftCard: string;
    shopId: number;
    shopType: number;
    orderType: number;
    orderName: string;
    paymentType: string;
    deliveryAddressId: number;
    actionbyLoginUserId: number;
    actionbyUserTypeEnumId: number;
    statusEnumId: number;
    actionOnDate: string;
    netAmount:number
    grossAmount:number
    totalTaxAmount:number
    totalDiscount:number
    totalTaxable:number
    roundOff:number
    orderDetail:Array<{
        product_id: number;
        order_detail_id:  number; 
        mrp: number;
        no_of_product: number;  
        unit_price :number;
        "sales_price":number;
      "fp_scheme_order_detail":Array<
        {"scheme_order_id":number,"scheme_id":number,"scheme_amount":number}
      >
    }>

    
}



export class AddQuotation implements Quotation{
  "quotation_id": Number;
  "quotation_number": String;
  "customer_id": Number;
  "quotation_for": String;
  "quotation_date": String;
  "valid_till": String;
  "total_amount": Number;
  "display_options": Number;
  "image_display_options": number;
  "status_enum_id": Number;
  "remarks": String;
  "created_by": Number;
  "items": [{ product_id: Number; product_image_id: Number; quantity: Number; unit_price: Number; tax_percent: Number; per_item_discount_amount: Number; total_discount_amount: Number; per_item_discount_percentage: number; total_discount__tax__qty_amount: Number; }];
}