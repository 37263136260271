import { ITranspoterDetails } from "src/app/core/interfaces/order/order-management/transpoter-detail/add-transpoter-detail";

export class ITranspoterDetailsModel implements ITranspoterDetails {
        remark: string;
        dispatchId: number;
        invoiceId: number;
        orderId : number;
        customerId:  number;   
        shopId : number;
        dispatchBy:number
        transportTypeId:number;
        transportId: number;
        docateNo: string;
        expecteddelieveryDate:string;
        dispatchAmount:number;
        files : string;
        vehicleNo: string;
        dispatchDetail: Array<
            {
                invoice_detail_id : number;
                dispatch_detail_id : number;
                product_id:number;
                dispatch_quantity: number;
            }
        >
        statusEnumId :number;
        actionbyLoginUserId: number;
        actionbyUserTypeEnumId:number;
    }

